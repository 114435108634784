<template>
  <v-app app>
    <v-navigation-drawer v-model="ver_menu_lateral" expand-on-hover>
      <div class="text-right">
        <v-btn icon @click="mostrarMenuLateral()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <v-list density="comfortable" nav v-if="$route.name !== 'LOGIN'"> <!-- comfortable // compact -->
        <v-list-item prepend-icon="mdi-home" title="Home" value="home" @click="goView('/')"></v-list-item>
        <v-list-item prepend-icon="mdi-silverware-variant" title="Mis Productos" value="my_products" @click="goView('/my_products')"></v-list-item>
        <v-list-item prepend-icon="mdi-food-fork-drink" title="Categorias" value="categories" @click="goView('/categories')"></v-list-item>
        <v-list-item prepend-icon="mdi-food-apple" title="Productos" value="productos" @click="goView('/products')"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <v-navigation-drawer v-model="ver_menu_tres_puntos" expand-on-hover location="right" v-if="$route.name !== 'LOGIN'">
      <v-spacer></v-spacer>
      <v-btn icon @click="mostrarMenuTresPuntos()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-list density="comfortable" nav v-if="$route.name !== 'LOGIN'">
        <v-list-item prepend-icon="mdi-account-circle" title="Cuenta" value="cuenta" @click="viewAccount()"></v-list-item>
        <v-list-item prepend-icon="mdi-logout" title="Cerrar Sesión" value="cerrar_sesion" @click="cerrarSesion()"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <v-app-bar color="teal-darken-4" v-if="$route.name !== 'LOGIN'">
      <template v-slot:image>
        <v-img gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"></v-img>
      </template>
      <template v-slot:prepend>
        <v-app-bar-nav-icon v-if="!ver_menu_lateral" @click="mostrarMenuLateral()"></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>{{$route.name}}</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="viewChat()">
        <v-icon>mdi-wechat</v-icon>
      </v-btn>
      <v-btn icon @click="mostrarMenuTresPuntos()">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>
    
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Cuenta</span>
        </v-card-title>
        <v-container fluid>
          <v-row justify="space-around">
            <v-col cols="12" sm="2" md="6">
              <v-text-field
                type="input"
                v-model="account.type_document"
                color="purple darken-2"
                label="Tipo de Documento"
                autocomplete="off"
                variant="outlined"
                density="compact"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="6">
              <v-text-field
                type="input"
                v-model="account.document"
                color="purple darken-2"
                label="Nro Documento"
                autocomplete="off"
                variant="outlined"
                density="compact"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="12">
              <v-text-field
                type="input"
                v-model="account.first_name"
                color="purple darken-2"
                label="Nombres"
                autocomplete="off"
                variant="outlined"
                density="compact"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="12">
              <v-text-field
                type="input"
                v-model="account.last_name"
                color="purple darken-2"
                label="Apellidos"
                autocomplete="off"
                variant="outlined"
                density="compact"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="6">
              <v-text-field
                type="input"
                v-model="account.phone"
                color="purple darken-2"
                label="Teléfono"
                autocomplete="off"
                variant="outlined"
                density="compact"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="6">
              <v-text-field
                type="input"
                v-model="account.vehicle"
                color="purple darken-2"
                label="Vehículo"
                autocomplete="off"
                variant="outlined"
                density="compact"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="12">
              <v-text-field
                type="input"
                v-model="account.email"
                color="purple darken-2"
                label="E-mail"
                autocomplete="off"
                variant="outlined"
                density="compact"
                readonly
              ></v-text-field>
            </v-col>
            </v-row>
            <v-row>
              <v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined dark class="mb-2" color="blue darken-1" @click="close()">
                  <v-icon dark left>mdi-minus-circle</v-icon>Aceptar                          
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>    

    <v-dialog v-model="dialogchat" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Chat</span>
        </v-card-title>
        <v-container fluid>
          <v-row justify="space-around">
            <v-col cols="12" sm="2" md="6">
              <table>
                <div v-for="message in messages" :key="message">
                  <tr>
                    <td v-if="message.vendor_customer=='V'">
                      <input class="my-right-class" v-model="message.message" type="text" readonly>
                    </td>
                    <td v-else>
                      <input class="my-left-class" v-model="message.message" type="text" readonly>
                    </td>
                  </tr>
                </div>
              </table>
            </v-col>
          </v-row>
           
          <v-row>
            <v-card-text>
              <v-text-field
                :loading="loading"
                density="compact"
                label="Mensaje"
                variant="solo"
                hide-details
                single-line
                v-model="new_msg"
                type="input"
                color="danger"
                bg-color="#eafaf1"
              ></v-text-field>
            </v-card-text>
          </v-row>
          
          <v-row>
            <v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark class="mb-2" color="red darken-1" @click="close()">
                  <v-icon dark left>mdi-minus-circle</v-icon>Cerrar Chat        
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn dark class="mb-2" color="green darken-1" @click="addNewMessage()" :disabled="new_msg==''">
                  <v-icon dark left>mdi-send</v-icon>Enviar
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import Home from './components/Home'
  import Swal from 'sweetalert2';
  import API from './api';

  import {getFirestore,onSnapshot,collection,doc,deleteDoc,setDoc,addDoc,orderBy,where,query,serverTimestamp,limit,runTransaction,getCountFromServer, endAt, startAt} from 'firebase/firestore';
  import {initializeApp} from "firebase/app";
  import {getAnalytics} from "firebase/analytics";
  import {ref,onUnmounted} from 'vue';

  const firebaseConfig = {
    apiKey: "AIzaSyCVyZWY2af-JJf2V-gvVPFAW7ANDWzJNI0",
    authDomain: "cropchien-b4abf.firebaseapp.com",
    projectId: "cropchien-b4abf",
    storageBucket: "cropchien-b4abf.appspot.com",
    messagingSenderId: "980970251855",
    appId: "1:980970251855:web:df66971ae5ed35025eec3a",
    measurementId: "G-JZQ3Q4D3F3"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);
  const updated_at_timestamp = serverTimestamp();
  
  export default {
    name: 'App',
    components: {
      Home
    },
    data () {
      return {
        ver_menu_lateral: false,
        ver_menu_tres_puntos: false,
        token_street_shopping: null,
        dialog: false,
        account: {
          type_document: null,
          document: null,
          first_name: null,
          last_name: null,
          phone: null,
          email: null,
          vehicle: null
        },
        is_user_connected: "false",
        order_accepted: 0,
        messages:ref([]),
        dialogchat: false,
        mounted_msg: false,
        new_msg: '',
        user_id_customer: null,
        user_id_vendor: null,
        current_date: null,
        loaded: false,
        loading: false,        
      }
    },
    methods: {
      mounted() {
        this.user_id_customer = 38 // Asignar dinamicamente
        this.user_id_vendor = 34 // Asignar dinamicamente
      },
      
      viewChat() {
        if (!this.mounted_msg) {
          this.mounted_msg = true;
          if (this.current_date == null) {
            this.current_date = localStorage.getItem('current_date_street_shopping');
          }

          const latestQuery = query(collection(db,"chat"),
            where("user_id_customer", "==", 38),
            where("user_id_vendor", "==", 34),
            where("date_msg", "==", '2025-01-25'),
            orderBy("updated_at"), startAt(1), limit(15)
          );
          
          const livemessages = onSnapshot(latestQuery,(snapshot)=>{
            this.messages = snapshot.docs.map((doc) => {
              return {
                id: doc.id,
                name: doc.data().name,
                vendor_customer: doc.data().vendor_customer,                
                message: doc.data().message                
              }
            });
          });
          onUnmounted(livemessages);
        }
        this.dialogchat = true;
      },
      
      goView(path_route) {
        if (path_route === '/chat') {
          this.$router.push(path_route);
        } else {
          this.ejectApp();
          if (this.order_accepted == 0 && this.is_user_connected == 'false') {
            this.$router.push(path_route);
          }
        }
        this.ver_menu_tres_puntos = false;
        this.ver_menu_lateral = false;
      },

      ejectApp() {
        this.is_user_connected = localStorage.getItem('is_connected_street_shopping');
        this.order_accepted = localStorage.getItem('order_accepted_street_shopping');
        if (this.order_accepted == null) {
          this.order_accepted = 0;
        }
        if (this.is_user_connected == null) {
          this.is_user_connected = 'false';
        }
      },

      mostrarMenuLateral() {
        this.ejectApp();
        if (this.order_accepted == 0 && this.is_user_connected == 'false') {
          this.ver_menu_tres_puntos = false;
          this.ver_menu_lateral = !this.ver_menu_lateral;
        } else {
          this.ver_menu_lateral = false;
        }
      },

      mostrarMenuTresPuntos() {        
        this.ver_menu_lateral = false;
        this.ver_menu_tres_puntos = !this.ver_menu_tres_puntos;
      },

      cerrarSesion() {
        this.ver_menu_tres_puntos = false;
        this.ver_menu_lateral = false;
        localStorage.removeItem('token_street_shopping');    
        this.goView('/login');
      },

      close() {
        this.dialog = false;
        this.dialogchat = false;
      },

      viewAccount() {
        this.loading = true;
        this.ver_menu_tres_puntos = false;
        this.token_street_shopping = localStorage.getItem('token_street_shopping');
        let formdata = new FormData();
        API.post('api.php', formdata, { headers: {
          'Authorization': this.token_street_shopping,
          'Route': 'get_account_details'
        }})
        .then(response => {
          if (response.data.transaction_status && response.data.transaction_status.code == 200) {
            this.account = response.data.Data;
            this.dialog = true;
          } else if (response.data.transaction_status && process.env.VUE_APP_API_INVALID_TOKEN.includes(response.data.transaction_status.code)) {
            localStorage.removeItem('token_street_shopping');
            this.goView('/login');
          } else {
            Swal.fire({
              title: 'Error interno',
              html: 'Se ha presentado una falla en el servidor',
              icon: 'error',
              confirmButtonText: 'Aceptar',
            });
          }
        })
        .catch(error => {
          Swal.fire({
            title: 'Error en la App',
            html: 'Se ha presentado una falla en la App',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        })
        .finally(() => this.loading = false)
      },

      addNewMessage() {
        addDoc(collection(db,'chat'),{
          message: this.new_msg,
          vendor_customer: "V",
          user_id_customer: this.user_id_customer,
          user_id_vendor: this.user_id_vendor,          
          updated_at: updated_at_timestamp,
          date_msg: this.current_date,      
        });
        this.new_msg = '';
      },
      
    }
  }
</script>

<style>
  .my-left-class {
    color:black !important;
    text-align: left !important;
  }
  .my-right-class {
    color:red !important;
    text-align: right !important;
  }
</style>